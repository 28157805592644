<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>{{title}} venue</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ <router-link to="/venue">Venues </router-link> \ {{title == 'Update' ? 'Edit' : 'Add'}} venue
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="card-body" v-if="loading">
              <v-skeleton-loader
                  type="table-row-divider@5">
              </v-skeleton-loader>
            </div>
            <form @submit.prevent="saveVenue()" v-if="!loading">
              <v-row>
                <v-col cols="12" sm="12" md="9">
                  <h4>Venue details</h4>
                </v-col>
                <v-col cols="12" sm="12" md="3" class="text-right">
                  <span class="badge badge-primary text-h6">
                    Venue code: {{ venue.unique_code }}
                  </span>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-autocomplete
                      outlined
                      dense item-text="name" :items="types"
                      item-value="value"
                      v-model="venue.type"
                      :error="$v.venue.type.$error"
                  >
                    <template v-slot:label>
                      Venue type <span class="text-danger">*</span>
                    </template>
                  </v-autocomplete>
                  <span class="text-danger" v-if="$v.venue.type.$error">This information is required</span>
                </v-col>

                <v-col cols="12" md="2">
                  <v-checkbox
                    v-if="venue.type != 'state'"
                    class="ml-2"
                    v-model="venue.host_other_candidates"
                    label="Host externals"
                  ></v-checkbox>
                </v-col>

                <v-col cols="12" md="6">
                  <v-chip class="mb-2" v-if="selectUser" close @click:close="removeAccountHolder">{{selectUser}}</v-chip>
                  <v-autocomplete
                      dense
                      v-if="venue.type == 'private'"
                      outlined
                      :search-input.sync="accountHolderSearch"
                      @change="selectedAccountHolder"
                      v-model="selectUser"
                      :filter="() => true"
                      :items="accountHolders"
                      clearable
                      item-text="custom_name_with_email"
                      item-value="id"
                      return-object
                  >
                    <template v-slot:label>
                      Select enroller 
                      <span class="text-danger">*</span>
                    </template>
                    <template v-slot:no-data>
                      <div class="p-3 font-size-sm">Enter the enroller name or email </div>
                    </template>
                  </v-autocomplete>
                  <span class="text-danger" v-if="$v.selectUser.$error">This information is required</span>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="venue.name"
                      outlined  :error="$v.venue.name.$error"
                      dense
                  >
                    <template v-slot:label>
                      Venue title <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.venue.name.$error">This information is required</span>
                  <span class="text-danger" v-if="errors.name" >{{errors.name[0]}}</span>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                      outlined
                      dense
                      :error="$v.venue.email.$error"
                      v-model="venue.email"
                  >
                    <template v-slot:label>
                      Email <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.venue.email.$error">This information is required</span>
                  <span class="text-danger" v-if="!$v.venue.email.email">This Email is invalid</span>

                  <span class="text-danger" v-if="errors.email" >{{errors.email[0]}}</span>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                      outlined :error="$v.venue.phone.$error"
                      dense
                      v-model="venue.phone"
                  >
                    <template v-slot:label>
                      Phone <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.venue.phone.$error">This information is required</span>
                </v-col>

                <v-col cols="12" sm="12" md="6"  v-if="currentUser.access_type!='score'">
                  <v-autocomplete @change="changeScoreData"
                            clearable
                            outlined
                            dense item-text="name" :items="scores"
                            item-value="id"
                            :error="$v.venue.score_id.$error"
                            v-model="venue.score_id"
                  >
                    <template v-slot:label>
                      SCORE <span class="text-danger">*</span>
                    </template>
                  </v-autocomplete>
                  <span class="text-danger" v-if="errors.score_id" >{{errors.score_id[0]}}</span>
                  <span class="text-danger" v-if="$v.venue.score_id.$error">This information is required</span>

                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                      type="number"
                      outlined
                      dense
                      v-model="venue.capacity"
                  >
                    <template v-slot:label>
                      Capacity
                    </template>
                  </v-text-field>


                </v-col>

                <v-col cols="12" sm="12" md="12">
                  <v-autocomplete
                      v-model="venue.location_id"
                      :items="locations" filled
                      outlined clearable  :loading="loading_location"
                      dense :error="$v.venue.location_id.$error"
                      item-text="name"
                      item-value="id"
                  >
                    <template v-slot:label>
                      Location <span class="text-danger">*</span>
                    </template>
                    <template v-slot:no-data>
                        <div class="no-data-auto-complete text-center">No data available </div>
                    </template>
                  </v-autocomplete>
                  <span class="text-danger" v-if="$v.venue.location_id.$error">This information is required</span>

                  <span class="text-danger" v-if="errors.location_id" >{{errors.location_id[0]}}</span>
                </v-col>
              </v-row>

              <v-row v-if="title != 'Update'">
                <!-- contact person information -->
                <v-col cols="12" sm="12" md="12">
                     <h4>Venue primary contact</h4>
                </v-col>

                <v-col cols="12" sm="12" md="4">
                  <v-text-field
                        outlined
                        dense
                        :error="$v.venue.contact_person_first_name.$error"
                        v-model="venue.contact_person_first_name"
                    >
                      <template v-slot:label>
                        First name <span class="text-danger">*</span>
                      </template>
                    </v-text-field>
                    <span class="text-danger" v-if="errors.contact_person_first_name" >{{errors.contact_person_first_name[0]}}</span>
                    <span class="text-danger" v-if="$v.venue.contact_person_first_name.$error">This information is required</span>
                </v-col>

<!--                <v-col cols="12" sm="12" md="4">-->
<!--                  <v-text-field-->
<!--                        outlined-->
<!--                        dense-->
<!--                        :error="$v.venue.contact_person_middle_name.$error"-->
<!--                        v-model="venue.contact_person_middle_name"-->
<!--                        label="Contact person Middle name"-->
<!--                    ></v-text-field>-->
<!--                    <span class="text-danger" v-if="errors.contact_person_middle_name" >{{errors.contact_person_middle_name[0]}}</span>-->
<!--                    <span class="text-danger" v-if="$v.venue.contact_person_middle_name.$error">This information is required</span>-->
<!--                </v-col>-->

                <v-col cols="12" sm="12" md="4">
                  <v-text-field
                        outlined
                        dense
                        :error="$v.venue.contact_person_last_name.$error"
                        v-model="venue.contact_person_last_name"
                    >
                      <template v-slot:label>
                        Surname <span class="text-danger">*</span>
                      </template>
                    </v-text-field>
                    <span class="text-danger" v-if="errors.contact_person_last_name" >{{errors.contact_person_last_name[0]}}</span>
                    <span class="text-danger" v-if="$v.venue.contact_person_last_name.$error">This information is required</span>
                </v-col>
                
                <v-col cols="12" sm="12" md="4">
                  <v-text-field
                      outlined  
                      :error="$v.venue.contact_person_email.$error"
                      dense
                      v-model="venue.contact_person_email"
                  >
                    <template v-slot:label>
                      Email <span class="text-danger">*</span>
                      </template>
                  </v-text-field>

                  <span class="text-danger" v-if="$v.venue.contact_person_email.$error">This data is invalid</span>
                </v-col>
                
                <v-col cols="12" md="4">
                    <v-text-field
                        outlined
                        dense
                        :error="$v.venue.contact_person_mobile.$error"
                        v-model="venue.contact_person_mobile"
                    >
                      <template v-slot:label>
                        Mobile <span class="text-danger">*</span>
                      </template>
                    </v-text-field>
                    <span class="text-danger" v-if="errors.contact_person_mobile" >{{errors.contact_person_mobile[0]}}</span>
                    <span class="text-danger" v-if="$v.venue.contact_person_mobile.$error">This information is required</span>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        outlined
                        dense
                        :error="$v.venue.contact_person_phone.$error"
                        v-model="venue.contact_person_phone"
                        label="Phone"
                    ></v-text-field>
                    <span class="text-danger" v-if="errors.contact_person_phone" >{{errors.contact_person_phone[0]}}</span>
                    <span class="text-danger" v-if="$v.venue.contact_person_phone.$error">This information is required</span>
                </v-col>
                <v-col cols="12" md="6">
                    <label>Role </label>
                    <v-textarea
                        outlined
                        dense
                        :error="$v.venue.contact_person_job_description.$error"
                        v-model="venue.contact_person_job_description"
                        label="Job Description"
                    ></v-textarea>
                    <span class="text-danger" v-if="errors.contact_person_job_description" >{{errors.contact_person_job_description[0]}}</span>
                    <span class="text-danger" v-if="$v.venue.contact_person_job_description.$error">This information is required</span>
                </v-col>
                <v-col cols="12" md="6">
                    <label>Notes </label>
                    <v-textarea
                        outlined
                        dense
                        :error="$v.venue.contact_person_job_notes.$error"
                        v-model="venue.contact_person_job_notes"
                        label="Notes"
                    ></v-textarea>
                    <span class="text-danger" v-if="errors.contact_person_job_notes" >{{errors.contact_person_job_notes[0]}}</span>
                    <span class="text-danger" v-if="$v.venue.contact_person_job_notes.$error">This information is required</span>
                </v-col>
                <!-- contact person information ends -->
              </v-row>
              
              <v-row>
                <!-- address section -->
                <v-col cols="12" sm="12" md="12">
                  <h4>Address</h4>
                </v-col>

                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                      outlined dense  :error="$v.venue.address_line_1.$error"
                      v-model="venue.address_line_1"
                  >
                    <template v-slot:label>
                      Address line 1 <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="errors.address_line_1" >{{errors.address_line_1[0]}}</span>
                  <span class="text-danger" v-if="$v.venue.address_line_1.$error">This information is required</span>
                </v-col>
                
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                      label="Address line 2" outlined dense
                      v-model="venue.address_line_2">
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="6">
                  <v-text-field 
                    outlined 
                    dense  
                    :error="$v.venue.suburb.$error"
                    v-model="venue.suburb"
                  >
                    <template v-slot:label>
                      Suburb <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="errors.suburb" >{{errors.suburb[0]}}</span>
                  <span class="text-danger" v-if="$v.venue.suburb.$error">This information is required</span>
                </v-col>

                <v-col cols="12" sm="12" md="6">
                  <v-text-field outlined dense :error="$v.venue.post_code.$error"
                                v-model="venue.post_code"
                  >
                    <template v-slot:label>
                      Post code <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.venue.post_code.$error">This information is required</span>
                  <span class="text-danger" v-if="errors.post_code" >{{errors.post_code[0]}}</span>
                </v-col>

                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete 
                    @change="toggleCountry"
                    v-model="venue.country_id"
                    :items="countries" filled
                    outlined
                    dense color="blue-grey lighten-2"
                    label="Select Country"
                    item-text="name"
                    item-value="id"
                  >
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="12" md="6" v-if="toggle.stateList==false">
                  <v-text-field label="State*" outlined dense :error="$v.venue.state.$error"
                                v-model="venue.state">
                  </v-text-field>
                  <span class="text-danger" v-if="$v.venue.state.$error">This information is required</span>

                </v-col>

                <v-col cols="12" sm="12" md="6" v-if="toggle.stateList">
                  <v-autocomplete
                      v-model="venue.state_id" :error="$v.venue.state_id.$error"
                      :items="states" filled
                      outlined
                      dense color="blue-grey lighten-2"
                      item-text="name"
                      item-value="id"
                  ><template v-slot:label>
                    State <span class="text-danger">*</span>
                  </template>
                  </v-autocomplete>
                  <span class="text-danger" v-if="$v.venue.state_id.$error">This information is required</span>
                </v-col>

                <v-col cols="12" sm="12" md="12">
                  Access instructions for attendees
                  <ckeditor :config="editorConfig"
                            v-model="venue.instructions" >
                  </ckeditor>
                </v-col>
                <!-- address section ends -->
                
                <!-- map section starts -->
                <v-col cols="12" sm="12" md="12">
                  <h4>Map</h4>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field 
                    type="url"
                    label="Map URL"
                    outlined 
                    dense
                    v-model="venue.map_url">
                  </v-text-field >
                </v-col>
                <v-col cols="12" sm="12"  md="6">
                  <v-file-input  
                    prepend-icon="" 
                    prepend-inner-icon="mdi-file" 
                    dense 
                    outlined 
                    @change="previewMapImage"
                    accept="image/*" 
                    v-model="venue.map_image"
                    label="Map upload"
                  ></v-file-input>
                  <div class="d-block" v-if="map_image!=null">
                    <v-img
                        :lazy-src="map_image"
                        max-height="120"
                        max-width="120"
                        class="img-thumbnail"
                        :src="map_image">
                      <v-btn @click="deleteImage('map_image')" icon small style="margin-left: 89px; padding-bottom: 11px" v-if="map_image">
                        <v-icon color="red">
                          fas fa-times
                        </v-icon>
                      </v-btn>
                    </v-img>
                  </div>
                </v-col>
                <!-- map section ends -->
<!--                <v-col cols="12" md="6">-->
<!--                    Central venue ?-->
<!--                    <v-switch-->
<!--                        v-model="venue.is_central_venue"-->
<!--                        :label="venue.is_central_venue ?'Yes' : 'No'"-->
<!--                    ></v-switch>-->
<!--                </v-col>-->

              </v-row>

              <!-- attachment section starts -->
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <h4>Attachments</h4>
                </v-col>

              </v-row> 

              <v-row>
                <v-col cols="6" sm="6" md="6">
                  <div class="d-block">
                    <v-file-input
                        dense
                        outlined
                        @change="previewImageOne"
                        accept="image/*"
                        v-model="venue.image_one"
                        prepend-icon=""
                        prepend-inner-icon="mdi-file"
                        label="Upload image"
                    ></v-file-input>
                  </div>
                  <div class="d-block" v-if="image_one!=null">
                    <v-img
                        :lazy-src="image_one"
                        max-height="120"
                        max-width="120"
                        class="img-thumbnail"
                        :src="image_one">
                      <v-btn @click="deleteImage('image_one')" icon small style="margin-left: 89px; padding-bottom: 11px" v-if="image_one">
                        <v-icon color="red">
                          fas fa-times
                        </v-icon>
                      </v-btn>
                    </v-img>
                  </div>
                </v-col>

                <v-col cols="6" md="6" v-if="image_one!=null">
                  <v-checkbox
                      v-model="venue.is_attach_one_visible_to_account_holder"
                      label="Provide to venue attendees"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6" sm="6" md="6">
                  <v-file-input
                      dense
                      outlined
                      @change="previewImageTwo"
                      prepend-icon=""
                      prepend-inner-icon="mdi-file"
                      accept="image/*"
                      v-model="venue.image_two"
                      label="Upload image"
                  >
                  </v-file-input>
                  <div class="d-block" v-if="image_two!=null">
                    <v-img
                        :lazy-src="image_two"
                        max-height="120"
                        max-width="120"
                        class="img-thumbnail"
                        :src="image_two"
                    >
                      <v-btn @click="deleteImage('image_two')" icon small style="margin-left: 89px; padding-bottom: 11px" v-if="image_two">
                        <v-icon color="red">
                          fas fa-times
                        </v-icon>
                      </v-btn>
                    </v-img>
                  </div>
                </v-col>

                <v-col cols="6" md="6" v-if="image_two!=null">
                  <v-checkbox
                      v-model="venue.is_attach_two_visible_to_account_holder"
                      label="Provide to venue attendees"
                  ></v-checkbox>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-file-input
                      dense
                      outlined
                      @change="previewImageThree"
                      prepend-icon=""
                      prepend-inner-icon="mdi-file"
                      accept="image/*"
                      v-model="venue.image_three"
                      label="Upload image"
                  ></v-file-input>
                  <div class="d-block" v-if="image_three!=null">
                    <v-img
                        :lazy-src="image_three"
                        max-height="120"
                        max-width="120"
                        class="img-thumbnail"
                        :src="image_three"
                    >
                      <v-btn @click="deleteImage('image_three')" icon small style="margin-left: 89px; padding-bottom: 11px" v-if="image_three">
                        <v-icon color="red">
                          fas fa-times
                        </v-icon>
                      </v-btn>
                    </v-img>
                  </div>
                </v-col>
                <v-col cols="12" md="6" v-if="image_three!=null">
                  <v-checkbox
                      v-model="venue.is_attach_three_visible_to_account_holder"
                      label="Provide to venue attendees"
                  ></v-checkbox>
                </v-col>
              </v-row>
              
              <v-row>
                <v-col cols="12" sm="6" md="6" @change="previewOtherImageAttachment">
                  <v-file-input
                      dense
                      outlined
                      prepend-icon=""
                      prepend-inner-icon="mdi-file"
                      accept="image/*"
                      v-model="venue.other_attachment"
                      label="Other attachments"
                  ></v-file-input>
                  <div class="d-block" v-if="other_attachment!=null">
                    <v-btn
                        @click="deleteImage('other_attachment')"
                        icon
                        small
                        v-if="other_attachment"
                    >
                      <v-icon>
                        fas fa-trash
                      </v-icon>
                    </v-btn>

                    <v-btn
                        v-if="this.title == 'Update' && other_attachment && !venue.other_attachment"
                        class="btn btn-primary text-white ml-3"
                        small
                        @click="viewAttachment(other_attachment.original)"
                    >
                      View Attachment
                    </v-btn>
                    <!-- <v-img
                        :lazy-src="other_attachment"
                        max-height="120"
                        max-width="120"
                        class="img-thumbnail"
                        :src="other_attachment"
                    >
                      <v-btn @click="deleteImage('other_attachment')" icon small style="margin-left: 89px; padding-bottom: 11px" v-if="other_attachment">
                        <v-icon color="red">
                          fas fa-times
                        </v-icon>
                      </v-btn>
                    </v-img> -->
                  </div>
                </v-col>     
                <v-col cols="12" md="6" v-if="other_attachment!=null">
                  <v-checkbox
                      v-model="venue.is_attach_other_visible_to_account_holder"
                      label="Provide to venue attendees"
                  ></v-checkbox>
                </v-col>           
              </v-row>
              
              <!-- attachment section ends -->
<!--                <v-col cols="12" sm="12" md="3">
                  Global ?
                  <v-switch
                      v-model="venue.is_available_global"
                      :label="venue.is_available_global ? 'Yes' : 'No'"
                  ></v-switch>
                </v-col>-->
                <v-col cols="12" sm="12" md="3">
                  Status
                  <v-switch
                      v-model="venue.is_active"
                      :label="venue.is_active ? 'Active' : 'Inactive'"
                  ></v-switch>
                  <span class="text-danger" v-if="errors.is_active" >{{errors.is_active[0]}}</span>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="text-right">
                  <v-btn
                      class="cancel-btn"
                      text
                      x-large
                      @click="back">
                    Cancel
                  </v-btn>
                  <v-btn color="ml-4 btn btn-primary mr-2" dark x-large @click="saveVenue()" :loading="loading">
                    Save
                  </v-btn>
                </v-col>
              </v-row>
            </form>
          </div>
        </div>
     </div>
    </div>
  </v-app>
</template>
<script>
import VenueService from "@/services/practical-exam/venue/VenueService";
import {required,email,numeric,requiredIf} from "vuelidate/lib/validators";
import ScoreService from "@/services/score/score/ScoreService";
import CountryService from "@/services/country/CountryService";
import StateService from "@/services/setting/state/StateService";
import LocationService from "@/services/practical-exam/location/LocationService";
import AccountHolderService from "@/services/user/accountholder/AccountholderService";

const location=new LocationService;
const state =new StateService;
const country=new CountryService();
const score = new ScoreService();
const venue=new VenueService;
const accountHolder = new AccountHolderService();

export default {
  validations:{
    venue:{
      name:{required},
      email:{required,email},
      phone:{required},
      score_id:{
        required: requiredIf(function (nestedModel){
          return this.currentUser.access_type!='score';
        }),
      },
      type: {required},
      location_id:{required},
      contact_person_first_name: {
        required :requiredIf(function (nestedModel){
          return this.title == 'Add';
        })
      },
      contact_person_middle_name: {},
      contact_person_last_name: {
        required :requiredIf(function (nestedModel){
          return this.title == 'Add';
        })
      },
      contact_person_email: {email, 
        required :requiredIf(function (nestedModel){
          return this.title == 'Add';
        })
      },
      contact_person_mobile: {
        required :requiredIf(function (nestedModel){
          return this.title == 'Add';
        })
      },
      contact_person_phone: {},
      contact_person_job_description: {},
      contact_person_job_notes: {},
      address_line_1:{required},
      suburb:{required},
      post_code:{required},
      state: {
        required: requiredIf(function (nestedModel) {
          return this.toggle.stateList==false;
        })
      },
      state_id:{
        required: requiredIf(function (nestedModel) {
          return this.toggle.stateList==true;
        })
      },
    },
    selectUser:{
      required: requiredIf(function (nestedModel) {
        return this.venue.type == 'private';
      })
    }
  },
  data(){
    return{
      title:'',
      errors:[],
      accountHolders:[],
      accountHolderSearch: '',
      selectUser: null,
      scores:[],
      countries:[],
      states:[],
      locations:[],
      types: [
        {name: 'Public',value:'state'},
        {name: 'Private',value:'private'},
        {name: 'Unspecified',value:'none'},
      ],
      venue:{
        name:'',
        email:'',
        phone:'',
        score_id:'',
        instructions:'',
        owner_remark:'',
        capacity:'',
        contact_person_first_name: '',
        contact_person_middle_name: '',
        contact_person_last_name: '',
        contact_person_email: '',
        contact_person_phone: '',                
        contact_person_mobile: '',
        contact_person_job_description: '',
        contact_person_job_notes: '',
        type:'',
        location_id:'',
        address_line_1:'',
        address_line_2:'',
        suburb:'',
        state:'',
        post_code:'',
        country_id:14,
        state_id:'',
        is_available_global:false,
        is_active:true,
        map_url:'',
        map_image:null,
        image_one:null,
        image_two:null,
        image_three:null,
        other_attachment:null,
        is_central_venue: false,
        is_attach_other_visible_to_account_holder: true,
        is_attach_one_visible_to_account_holder: true,
        is_attach_two_visible_to_account_holder: true,
        is_attach_three_visible_to_account_holder: true,
        host_other_candidates: false,
      },
      map_image:null,
      image_one:null,
      image_two:null,
      image_three:null,
      other_attachment:null,
      currentUser:{},
      editorConfig: {
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' ] ],
        versionCheck: false
      },
      toggle:{
        stateList:true
      },
      loading_location:false,
      loading:true,
      selectedEnroller: ''
    }
  },
  watch: {
    accountHolderSearch(val) {

      let data = {
        info:val,
      };
      this.searchAccountHolder(data);
    },
  },
  methods:{
    async searchAccountHolder(data)
    {
      accountHolder
          .search(data)
          .then((response) => {
            this.accountHolders = response.data.accountHolders;
          })
          .catch(() => {})
          .finally(() => (this.isLoading = false))
    },
    selectedAccountHolder() {
      this.selectedEnroller = this.selectUser;
      this.venue.owner_id = this.selectUser.id;
      this.selectUser = this.selectUser.full_name;
      this.setVenuePrimaryContact();
    },
    setVenuePrimaryContact(){
      this.venue.contact_person_first_name = this.selectedEnroller.first_name;
      this.venue.contact_person_last_name = this.selectedEnroller.last_name;
      this.venue.contact_person_email = this.selectedEnroller.email;
      this.venue.contact_person_mobile = this.selectedEnroller.mobile;
      this.venue.contact_person_phone = this.selectedEnroller.phone;
    },
    removeAccountHolder() {
      this.venue.owner_id = null;
      this.selectUser = null;
    },
    toggleCountry(){

      if(this.venue.country_id != 14){
        this.toggle.stateList=false;
      }else{
        this.toggle.stateList=true;
      }
    },
    back() {
      this.$tabs.close();
      this.$router.push({
        name: "venue",
      });
    },
    getCountries(){
      country
          .getAllCountry()
          .then((response) => {

            this.countries = response.data.data;
          })
          .catch((error) => {

          })
    },
    convertToFormData() {
      // if(this.venue.host_other_candidates==false){
      //   this.venue.host_other_candidates=0
      // }
      // if(this.venue.host_other_candidates==true){
      //   this.venue.host_other_candidates=1
      // }
      let formData = new FormData();
      for (let key in this.venue){
        if (key === "map_file" && this.venue[key] != null && this.venue[key] != undefined) {
          formData.append('map_file', this.venue[key]);
        }else if(key === "image_one" && this.venue[key] != null && this.venue[key] != undefined){
          formData.append('image_one', this.venue[key]);
        } else if(key === "image_two" && this.venue[key] != null && this.venue[key] != undefined){
          formData.append('image_two', this.venue[key]);
        }else if(key === "image_three" && this.venue[key] != null && this.venue[key] != undefined){
          formData.append('image_three', this.venue[key]);
        }else if(key === "other_attachment" && this.venue[key] != null && this.venue[key] != undefined){
          formData.append('other_attachment', this.venue[key]);
        } else {
          // if(this.venue[key]){
            formData.append(key,this.venue[key]);
          // }
        }
      }
      return formData;
    },
    saveVenue(){
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        this.loading=true;
        let formData = this.convertToFormData();
        if (this.venueId != null || this.venueId != undefined) {
          venue
              .update(this.venueId, formData)
              .then(response => {
                this.$snotify.success("Venue updated");
                this.loading=false;
                this.venueDetail();
                this.back();
              })
              .catch(error => {
                this.errors = error.errors;
              })
        } else {
          venue
              .create(formData)
              .then(response => {
                this.$snotify.success("Venue added");
                this.loading=false;
                this.back();
              })
              .catch(error => {
                this.errors = error.errors;
              })
        }
      }
    },
    venueDetail() {
      this.loading =true
      if(this.venueId != null || this.venueId !=undefined ){
        this.title='Update';
        venue
            .show(this.venueId)
            .then(response=> {
              this.venue = response.data.venue;
              this.toggleCountry();
              this.changeScoreData();
              if(this.venue.map_image_path){
                this.map_image=this.venue.map_image_path.thumb;
              }
              if(this.venue.image_one_path){
                this.image_one=this.venue.image_one_path.thumb;
              }
              if(this.venue.image_two_path){
                this.image_two=this.venue.image_two_path.thumb;
              }
              if(this.venue.image_three_path){
                this.image_three=this.venue.image_three_path.thumb;
              }
              if(this.venue.other_attachment_path){
                this.other_attachment=this.venue.other_attachment_path;
              }
              this.selectUser = this.venue.owner;

              this.loading =false
            })
      }
      else {
        this.title='Add';
        this.map_image=null;
            this.image_one=null;
            this.image_two=null;
            this.image_three=null;
            this.other_attachment=null;
            this.venue={
          name:'',
          email:'',
          phone:'',
          score_id:'',
          instructions:'',
          owner_remark:'',
          capacity:'',
          contact_person_full_name:'',
          contact_person_email:'',
          contact_person_full_address:'',
          contact_person_phone:'',
          type:'state',
          location_id:'',
          address_line_1:'',
          address_line_2:'',
          suburb:'',
          state:'',
          post_code:'',
          country_id:14,
          state_id:'',
          is_available_global:false,
          host_other_candidates: false,
          is_active:true,
          map_url:'',
          map_image:null,
          image_one:null,
          image_two:null,
          image_three:null,
          other_attachment:null,
          is_attach_one_visible_to_account_holder: true,
          is_attach_two_visible_to_account_holder: true,
          is_attach_three_visible_to_account_holder: true,
          is_attach_other_visible_to_account_holder: true,
        };
            this.loading =false
      }

    },
    getAllScore(){
      score
          .getScores()
          .then(response => {
            this.scores = response.data.scores;
          })
          .catch((err) => {

          });
    },
    previewMapImage(){
      if(this.venue.map_image != null){
        this.map_image= URL.createObjectURL(this.venue.map_image)
      }else{
        this.map_image = null;
      }
    },
    previewImageOne(){
      if(this.venue.image_one != null){
        this.image_one= URL.createObjectURL(this.venue.image_one)
      }else{
        this.image_one = null;
      }
    },
    previewImageTwo(){
      if(this.venue.image_two != null){
        this.image_two= URL.createObjectURL(this.venue.image_two);
      }else{
        this.image_two = null;
      }
    },
    previewImageThree(){
      if(this.venue.image_three != null){
        this.image_three= URL.createObjectURL(this.venue.image_three)
      }else{
        this.image_three = null;
      }
    },
    previewOtherImageAttachment(){
      if(this.venue.other_attachment != null){
        this.other_attachment= URL.createObjectURL(this.venue.other_attachment)
      }else{
        this.other_attachment = null;
      }
    },
    getAllState()
    {
      state
      .all()
          .then(response => {
            this.states = response.data.data;
          })
          .catch((err) => {

          });
    },
    changeScoreData() {
      this.getAllLocations();
    },
    getAllLocations(){
      this.loading_location=true;
      location
          .all(this.venue)
          .then(response => {
            this.locations = response.data.locations;
            this.loading_location=false;
          })
          .catch((err) => {

          });
    },
    getCurrentUser() {
      this.currentUser =  this.$store.getters.currentUser;
    },
    deleteImage(type){
      if(this.venueId){
        this.$confirm({
          message: `Are you sure? `,
          button: {
            no: "No",
            yes: "Yes",
          },
          callback: (confirm) => {
            if (confirm) {
              let data={}
              if(type){
                data={'file':type}
              }
              venue
                  .deleteImage(data,this.venueId)
                  .then(response => {
                    this.venueDetail();
                    if(type=='map_image'){
                      this.previewMapImage();
                      this.map_image=null;
                    }
                    if(type=='image_one'){
                      this.previewImageOne();
                      this.image_one=null;
                    }
                    if(type=='image_two'){
                      this.previewImageTwo();
                      this.image_two=null;
                    }
                    if(type=='image_three'){
                      this.previewImageThree();
                      this.image_three=null;
                    }
                    if(type=='other_attachment'){
                      this.previewOtherImageAttachment();
                      this.other_attachment=null;
                    }
                    this.$snotify.success("File Removed Successfully ");
                  })
                  .catch((err) => {

                  });
            }
          },
        });
      }else{
        if(type=='map_image'){
          this.previewMapImage();
          this.map_image=null;
          this.venue.map_image=null;
        }
        if(type=='image_one'){
          this.previewImageOne();
          this.image_one=null;
          this.venue.image_one=null;
        }
        if(type=='image_two'){
          this.previewImageTwo();
          this.image_two=null;
          this.venue.image_two=null;
        }
        if(type=='image_three'){
          this.previewImageThree();
          this.image_three=null;
          this.venue.image_three=null;
        }
        if(type=='other_attachment'){
          this.previewOtherImageAttachment();
          this.other_attachment=null;
          this.venue.other_attachment=null;
        }
      }

    },
    viewAttachment(other_attachment){
      window.open(other_attachment, "_blank");
    }
  },
  mounted() {
    this.toggleCountry();
    this.getCurrentUser();
    this.venueDetail();
    this.getAllScore();
    this.getCountries();
    this.getAllState();
    if(this.currentUser.access_type=='score'){
      this.getAllLocations();
    }

  },
  computed:{
    venueId() {
      return this.$route.params.venueId;
    },
  },
  routeTab(){
    if(this.venue){
      let title = this.venue.name + '- Update venue'
      return {
        title: title
      }
    }
  }
}
</script>